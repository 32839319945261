// paper & background
$paper: #24242487;
$darkPaper:#181818;
$navbar:#333333;

// primary
$primaryLight: #B2B2B2; 
$primaryMain: #F2F2F2;
$primaryDark: #383A6D;

$primary200: #3B3486;
$primary800: #3C4048;

// secondary
$secondaryLight: #F2F2F2;
$secondaryMain: #383A6D;
$secondaryDark: #3C4048; 

$secondary200: #D1D1D1;
$secondary800: #EFEFEF;

// success Colors
$successLight: #b9f6ca;
$success200: #69f0ae;
$successMain: #00e676;
$successDark: #0f2017;

// error
$errorLight: #d691f8;
$errorMain: #ed444f;
$errorDark: #86748f;

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;

// warning
$warningLight: #fff8e1;
$warningMain: #ffe57f;
$warningDark: #ffc107;

// grey
$grey50: #fafafa;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;

$grey500: #cbc8c8;
$grey600: #757575;
$grey700: #616161;
$grey900: #181818;



// ==============================|| JAVASCRIPT ||============================== //

:export {
    // paper & background
    paper: $paper;
    darkPaper:$darkPaper;
    navbar:$navbar;

    // primary
    primaryLight: $primaryLight;
    primary200: $primary200;
    primaryMain: $primaryMain;
    primaryDark: $primaryDark;
    primary800: $primary800;

    // secondary
    secondaryLight: $secondaryLight;
    secondary200: $secondary200;
    secondaryMain: $secondaryMain;
    secondaryDark: $secondaryDark;
    secondary800: $secondary800;

    // text:
    darkTextPrimary : $secondaryLight;
    darkTextSecondary: $secondaryMain;
    
    // success
    successLight: $successLight;
    success200: $success200;
    successMain: $successMain;
    successDark: $successDark;

    // error
    errorLight: $errorLight;
    errorMain: $errorMain;
    errorDark: $errorDark;

    // orange
    orangeLight: $orangeLight;
    orangeMain: $orangeMain;
    orangeDark: $orangeDark;

    // warning
    warningLight: $warningLight;
    warningMain: $warningMain;
    warningDark: $warningDark;

    // grey
    grey50: $grey50;
    grey100: $grey100;
    grey200: $grey200;
    grey300: $grey300;
    grey500: $grey500;
    grey600: $grey600;
    grey700: $grey700;
    grey900: $grey900;

    
}
